import React, { useLayoutEffect, useState } from 'react'
import { Box , Stack} from "@mui/material";
import Header from "./Header";




const wider ={
  width : 'auto',
  minWidth :'90%'
  
}

function SurveyGuide() {
  
    
    const downFile = (url) => {
        const fileName = url.split("/").pop()
        const aTag  = document.createElement("a")
        aTag.href=url
        aTag.setAttribute("download", fileName)
        document.body.appendChild(aTag)
        aTag.click()
        aTag.remove()
      }
   
    
    
    return (
      <Box p="20px" >
        <Header
          title="Survey Guide"
          subtitle="Dashboard / Survey Guide"
        />
        <Stack direction="row" spacing={2} justifyContent={'flex-start'}>
            
          </Stack>
        <Box
          m="20px 0 0 0"
          className="bg-white"
          sx={wider}
          
        >
            <object class="pdf my-viewer" 
            data={'/PG_SURVEY_APP_USER_MANUAL.pdf'}
            
                        >
                            <p style={{padding:'20px'}}>Your web browser doesn't have a PDF plugin.
  Instead you can <a href="javascript:;" onClick={()=>{downFile('/PG_SURVEY_APP_USER_MANUAL.pdf')}}>click here to
  download the PDF file.</a></p>
                </object>
          
        </Box>
        
      </Box>
    );
  };

export default SurveyGuide



